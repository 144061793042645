<template>
    <section class="d-flex justify-center">
      <div class="justify-center d-flex flex-column w-100">
        <div>
          <v-card color="#232126" dark rounded="xl" class="px-4 py-2">
            <v-card-title class="f15 ltr py-1 px-1">
              <span class="d-block w-100 text-right">{{question.title}}</span>
            </v-card-title>

            <v-card-actions class="pt-1 px-1">
              <div class="d-flex align-items-center justify-content-center w-100">
                <span>{{progressPercentage}}%</span>
                <v-progress-linear reverse
                  height="10"
                  :value="progressPercentage"
                  background-color="white"
                  color="primary"
                  class="flex-grow-1 mx-2"
                >
                </v-progress-linear>
                <div class="white rounded-pill px-2 py-1 d-flex primary--text">
                  <span>{{questionNumber}}</span> <span class="mx-1">{{$t('from')}}</span> <span>{{this.examQuestionsListState.length}}</span>
                </div>
              </div>
            </v-card-actions>
          </v-card>
        </div>
        <div v-if="question.type === 'NORMAL'">
          <v-radio-group v-model="newResult.questionItemId" mandatory hide-details>
            <div
              v-for="item in sortedItem" :key="item.id"
              class="pa-3 my-1" :class="{
                'rectangle-selected': item.id === newResult.questionItemId,
                'rectangle': item.id !== newResult.questionItemId}">
              <v-radio class="text-right"
                :label="item.title"
                :value="item.id">
              </v-radio>
            </div>
          </v-radio-group>
        </div>
        <div class="mt-14 d-flex align-content-center" v-if="question.type === 'LIKERT'">
          <div class="w-100 ltr">
            <v-slider v-model="newResult.questionItemId"
              :thumb-size="24" thumb-label="always" ticks="always" tick-size="6" hide-details
              :min="question.likertItem.startFrom"
              :max="question.likertItem.endTo"/>
          </div>
        </div>
        <div class="mb-2 mt-0">
            <v-row>
              <v-col cols="6" class="pa-2">
                <v-btn block color="light-blue lighten-1 white--text"
                  :disabled="!newResult.questionItemId || waitForResponce"
                  @click="nextQuestion()">
                  {{progressPercentage === 100 ? 'پایان آزمون' : 'بعدی'}}
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-2">
                <v-btn block color="gray lighten-5 black--text"
                  :disabled="waitForResponce"
                  @click="preQuestion()">
                  قبلی
                </v-btn>
              </v-col>
            </v-row>
        </div>
      </div>
    </section>
</template>
<script>
export default {
  name: "ExamQuestion",
  data: () => ({
    code: undefined,
    questionNumber: undefined,
    question: {},
    newResult: {
      id: undefined,
      questionId: undefined,
      questionItemId: undefined,
      examAssignId: undefined
    },
    waitForResponce: false
  }),
  computed: {
    examToDoState: {
      get () {
        return this.$store.getters['exam/getExamToDo']
      },
      set (val) {
        this.$store.commit('exam/updateExamToDo', val)
      }
    },
    examQuestionsListState: {
      get () {
        return this.$store.getters['exam/getExamQuestionsList']
      },
      set (val) {
        this.$store.commit('exam/updateExamQuestionsList', val)
      }
    },
    loadingBarState: {
      get () {
        return this.$store.getters['getLoadingBar']
      },
      set (val) {
        this.$store.commit('updateLoadingBar', val)
      }
    },
    sortedItem () {
      var its = []
      if(this.question && this.question.items) {
        its = this.question.items
      }
      return its.sort(function(a,b){return a.number - b.number})
    },
    progressPercentage () {
      return Math.floor(this.questionNumber / this.examQuestionsListState.length * 100)
    }
  },
  beforeCreate() {
    this.code = undefined,
    this.questionNumber = undefined,
    this.question = {},
    this.selectedId = undefined
    if(!this.examQuestionsListState) {
      this.$store.dispatch('exam/examQuestionsListFromServerAction', this.$route.params.code)
    }
  },
  mounted() {
    this.loadNewQuestion()
  },
  methods: {
    loadNewQuestion () {
      this.waitForResponce = true
      this.loadingBarState = true
      this.newResult = {
        id: undefined,
        questionId: undefined,
        questionItemId: undefined,
        examAssignId: undefined
      }
      this.code = this.$route.params.code
      this.questionNumber = this.$route.params.questionNumber
      this.$axios.get('exam/todo/' + this.code + '/' + this.questionNumber)
        .then((response) => {
          this.question = response.data
          this.newResult.examAssignId = this.question.examAssignId
          this.newResult.questionId = this.question.id
          if(this.question.selectedId) {
            this.newResult.id = this.question.selectedId
            this.newResult.questionItemId = this.question.selectedItemId
          }
        })
        .catch((error) => {
          this.question = {}
          console.log(error)
          this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
        })
        .then(() => {
          this.loadingBarState = false
          this.waitForResponce = false
        })
    },
    nextQuestion() {
      this.waitForResponce = true
      this.loadingBarState = true
      if(this.newResult.questionItemId) {
        this.saveNewResult(this.newResult)
        .then(response => {
          console.log(response)
          this.gotToTheNextQuestion()
        }).catch(error => {
          this.waitForResponce = false
          console.error(error)
          this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
        })
        .then(() => {
          this.loadingBarState = false
        })
      } else {
        this.gotToTheNextQuestion()
      }
    },
    preQuestion() {
      this.waitForResponce = true
      this.loadingBarState = true
      if(this.newResult.questionItemId) {
        this.saveNewResult(this.newResult)
        .then(response => {
          console.log(response)
          this.gotToThePreviousQuestion()
        }).catch(error => {
          this.waitForResponce = false
          console.error(error)
          this.$toast.error(err.message, { rtl: this.$vuetify.rtl });
        })
        .then(() => {
          this.loadingBarState = false
        })
      } else {
        this.gotToThePreviousQuestion()
      }
    },
    gotToTheNextQuestion() {
      var nextNumber = -1
      for (var i = 1; i <= this.examQuestionsListState.length; i++) {
        if (this.examQuestionsListState.some(e => e.questionNumber === parseInt(this.questionNumber) + i)) {
          nextNumber = parseInt(this.questionNumber) + i
          break
        }
      }
      if(nextNumber === -1) {
        this.$router.push({ name: 'ExamConfirmation' })
      } else {
        this.$router.push({ name: 'ExamQuestion', params: { questionNumber: nextNumber } })
        this.loadNewQuestion()
      }
    },
    gotToThePreviousQuestion() {
      var nextNumber = -1
      for (var i = 1; i <= this.examQuestionsListState.length; i++) {
        if (this.examQuestionsListState.some(e => e.questionNumber === parseInt(this.questionNumber) - i)) {
          nextNumber = parseInt(this.questionNumber) - i
          break
        }
      }
      if(nextNumber === -1) {
        this.$router.push('./')
      } else {
        this.$router.push({ name: 'ExamQuestion', params: { questionNumber: nextNumber } })
        this.loadNewQuestion()
      }
    },
    saveNewResult(resultDTO) {
      return new Promise((resolve, reject) => {
        this.$axios.post('exam/todo/' + this.code +
          '/' + this.questionNumber, resultDTO)
        .then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
</script>
<style scoped>
  .rectangle {
    border-radius: 12px;
    border: solid 1px #f6f6f8;
    background-color: #ffffff;
  }
  .rectangle-selected {
    border-radius: 12px;
    background-color: #f0f7ff;
  }
  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
    text-align: justify;
  }
</style>
